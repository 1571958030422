:root {
  --primary-color: #5B48CD;
  --secondary-color: #444;
  --background-light: #fff;
  --background-dark: #1e1e1e;
  --task-bg-light: #f4f4f4;
  --task-bg-dark: #444;
  --text-color-light: #000;
  --text-color-dark: #fff;
  --hover-color: #4735af;
  --card-shadow: 0px 4px 9px 1px rgba(0, 0, 0, 0.1);
  --card-radius: 10px;
  --transition-time: 0.4s;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  transition: background-color var(--transition-time), color var(--transition-time);
}

body {
  font-family: "Poppins", sans-serif;
  background-color: var(--secondary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.app {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.card {
  display: flex;
  flex-direction: row;
  width: 900px;
  height: 570px;
  background-color: var(--background-light);
  box-shadow: var(--card-shadow);
  border-radius: var(--card-radius);
  position: relative;
}

.card-left {
  background-color: var(--primary-color);
  color: var(--text-color-dark);
  padding: 20px;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: var(--card-radius) 0 0 var(--card-radius);
}

.text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  line-height: 180px;
}

.text-container h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 345px;
  padding-top: 10px;
}

.text-container p {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 70px;
}

.ver {
  padding-top: 49px;
}

.card-right {
  padding: 20px;
  width: 55%;
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
}

.task-list {
  margin-top: 10px;
  font-size: 17px;
  display: flex;
  flex-direction: column;
  gap: 22px;
  height: 87%;
  max-height: 100%;
  overflow-y: auto;
  padding-right: 16px;
  scrollbar-color: var(--primary-color) var(--task-bg-light);
  scrollbar-width: thin;
}

.task-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--task-bg-light);
  padding: 33px;
  border-radius: 7px;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.task-card h3 {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  flex-grow: 1;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.task-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  background-color: var(--background-light);
  width: 100%;
  box-sizing: border-box;
}

.task-form input,
.task-form textarea {
  font-family: "Poppins", sans-serif;
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: none;
  background-color: var(--background-light);
  font-size: 20px;
}

.task-form input {
  height: 40px;
}

.task-form textarea {
  height: 290px;
  resize: vertical;
  overflow-y: auto;
}

.submit-button,
.back-button {
  padding: 10px;
  margin-top: 10px;
  border: none;
  border-radius: 19px;
  cursor: pointer;
}

.submit-button {
  background-color: var(--primary-color);
  color: var(--text-color-dark);
}

.submit-button:hover {
  background-color: var(--hover-color);
}

.back-button {
  background-color: #ddd;
  color: #333;
}

.back-button:hover {
  background-color: #ccc;
}

.add-task-button {
  position: absolute;
  bottom: 17px;
  left: 70%;
  transform: translateX(-50%);
  background-color: var(--primary-color);
  color: var(--text-color-dark);
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-task-button:hover {
  background-color: var(--hover-color);
}

.switch {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 39px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: var(--transition-time);
  border-radius: 33px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 32px;
  width: 32px;
  border-radius: 50%;
  left: 4px;
  top: 3.2px;
  background-color: var(--background-light);
  transition: var(--transition-time);
}

input:checked + .slider {
  background-color: #3a2999;
}

input:checked + .slider:before {
  transform: translateX(30px);
  background-color: #ffc800;
}

.no-tasks-message {
  text-align: center;
  font-size: 23px;
  color: var(--primary-color);
  margin-top: 220px;
}

body.dark {
  background-color: var(--background-dark);
  color: var(--text-color-dark);
}

.app.dark .card {
  background-color: #333;
}

.card-left.dark {
  background-color: #2d2d2d;
}

.task-card.dark {
  background-color: var(--task-bg-dark);
}

.dark .back-button {
  background-color: var(--task-bg-dark);
  color: var(--text-color-dark);
}
.dark .back-button:hover{
  background-color: #3a3a3a;
  color: var(--text-color-dark);
}
.add-task-button.dark {
  background-color: #3a2999;
}

.dark .task-form input,
.dark .task-form textarea {
  background-color: var(--task-bg-dark);
  color: var(--text-color-dark);
}

.dark .no-tasks-message {
  color: var(--text-color-dark);
}

.dark .task-card {   
  background-color: var(--task-bg-dark);   
  color: var(--text-color-dark); 
} 
  
.dark .task-form {   
  background-color: #333;
}

.delete-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.material-symbols-outlined {
  font-size: 24px;
  color: var(--text-color-light);
}

.dark .material-symbols-outlined {
  color: var(--text-color-dark);
}

.material-symbols-outlined:hover {
  color: #FF4444;
}

.color-circle {
  width: 17px;
  height: 17px;
  border-radius: 50%;
  margin-right: 10px;
  cursor: pointer;
  flex-shrink: 0;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  visibility: hidden;
}

.modal.show {
  opacity: 1;
  visibility: visible;
}

.modal.fade-out {
  opacity: 0;
  visibility: hidden;
}

.modal-content {
  background: #fff;
  padding: 20px 40px;
  border-radius: 7px;
  text-align: center;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  transform: translateY(-20px);
  transition: transform 0.3s ease;
}

.modal.show .modal-content {
  transform: translateY(0);
}

.modal-content h3 {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  margin-bottom: 10px;
  font-size: 17px;
  color: #333;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.yes-button,
.no-button {
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  color: #333;
  background: none;
}

.yes-button:hover {
  color: #FF4444;
}

.no-button:hover {
  color: #00C851;
}

.date-time-container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.task-form input[type="date"]{
  width: 130%;
  padding: 10px;
  border-radius: 5px;
  font-size: 1rem;
  text-align: center;
}

.task-form input[type="time"] {
  width: 100px; /* Set a specific width for AM/PM selector */
  padding: 10px;
  border-radius: 5px;
  font-size: 1rem;
  text-align: center;
}

.dark .task-form input[type="date"],
.dark .task-form input[type="time"] {
  background-color: var(--task-bg-dark);
  color: var(--text-color-dark);
}

.task-form input[type="date"],
.task-form input[type="time"] {
  background-color: var(--background-light);
  color: #333;
}
.time-selector {
  display: flex;
  align-items: center; /* Center vertically */
  gap: 10px; /* Space between input and dropdown */
  width: 100%;
}

.time-selector input[type="time"] {
  flex: 1; /* Take remaining space */
  padding: 10px;
  border-radius: 4px;
  border: none;
}

.time-selector select {
  background-color: var(--background-light);
  color: #333;
  width: 50px; /* Set a specific width for AM/PM selector */
  padding: 10px; /* Add some padding for better appearance */
  border-radius: 4px;
  border: none;
}

.dark .time-selector select {
  background-color: var(--task-bg-dark);
  color: var(--text-color-dark);
}
.datetime {
  font-size: 14px;
  margin-top: 7px;
}
.alert-box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff; /* Yellow background */
  color: #000; /* Black text */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000; /* Ensure it appears above other content */
}

.alert-content {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
}

.ok-button {
  margin-top: 10px;
  padding: 8px 27px;
  background-color:  var(--primary-color);
  color: white; /* White text */
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.ok-button:hover{
  background-color: var(--hover-color);
}
/* Styling for Guide Button */
.guide-button {
  position: absolute;
  margin-left: 420px;
  bottom: 20px;
  background: none;
  border: none;
  cursor: pointer;
}
.guide-icon {
  font-size: 34px; /* Set the icon size here */
}

.guide-content {
  padding: 10px; /* Padding inside the section */
  margin-bottom: 10px; /* Space below the guide section */
  transition: background-color 0.3s; /* Smooth background transition */
}

.guide-title {
  color: #333; /* Dark color for the title */
  font-size: 28px; /* Larger font size for the title */
  margin-bottom: 10px; /* Space below the title */
  font-weight: bold; /* Bold text for emphasis */
}
.dark .guide-title {
  color: #ffffff; /* Dark color for the title */
}
.guide-list {
  list-style-type: none; /* Remove default list styling */
  padding: 0; /* Remove padding */
}

.guide-list li {
  padding: 10px; /* Padding inside list items */
  margin: 5px 0; /* Space between list items */
  transition: transform 0.2s, box-shadow 0.2s; /* Smooth transition for hover effects */
}
.dark .guide-list li {
  color: #ccc;
}
.guide-list li strong {
  color:var(--primary-color);
}
.back-button-guide{
  background-color: var(--primary-color);
  color: var(--text-color-dark);
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border: none;
  border-radius: 19px;
  cursor: pointer;
}